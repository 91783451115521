<!--
 * @Author: your name
 * @Date: 2021-04-27 16:55:32
 * @LastEditTime: 2021-04-27 17:55:13
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \jlgh\src\views\school2.vue
-->
<template>
  <div class="container">
    <navigation msg="学校"></navigation>
    <div @click="toback" class="okcla">确认</div>
    <div class="seclass">
      <input style="width:5rem" v-model="schoolname" placeholder="搜索院校名" type="text" />
      <van-icon size=".5rem" name="search" />
    </div>
    <div class="yuanx">
      <div @click="sesc(item)" class="bdj" v-for="(item,index) in selemsg" v-bind:key="index">{{item.name}}</div>
    </div>
  </div>
</template>
<script>
import navigation from "../../components/navigation/index";
import school from "../../util/school.json";
export default {
  components: {
    navigation,
  },
  data() {
    return {
      schoolname: "",
      schooljson: [],
      selemsg:"", //展示过滤数据
    };
  },
  created() {
    this.initmsg();
  },
  watch: {
    schoolname(oldmsg) {
      this.getpimsg(oldmsg);
    },
  },
  methods: {
    //获取匹配数据
    getpimsg(msg) {
      this.selemsg = this.schooljson.filter((e) => {
        if((e.name+'').indexOf(msg)!=-1){
            return true;
        }
      });
    },
    initmsg() {
      school.provs.forEach((e) => {
        this.schooljson = this.schooljson.concat(e.univs);
      });
    },
    //选择院校
    sesc(item){
        this.schoolname = item.name;
    },
    //确认
    toback(){
        if(this.schoolname==""){
            this.$toast("请输入院校");
            return;
        }
        // localStorage.setItem("eduSchool",this.schoolname)
        this.$router.push({path:"/school",query:{school:this.schoolname}})
    }
  },
};
</script>
<style scoped>
.container{
  position: fixed;
  height:100%;
  width:100%;
  background-color: #fff;
  top: 0;
  bottom:0;
  overflow-y:scroll;
  overflow-x:hidden;
}
.bdj{
    padding: .1rem .28rem;
}
.yuanx {
  font-size: 0.28rem;
  line-height: 0.4rem;
  margin: 0.43rem 0.4rem;
  border: 1px solid #979797;
  border-radius: 0.05rem;
  margin-top: 0;
  border-top: none;
  max-height: 6rem;
  overflow: auto;
  margin-top: -1px;
}
.okcla {
  font-size: 0.28rem;
  line-height: 0.48rem;
  color: #ff4800;
  position:absolute;
  top:0.25rem;
  right:0.2rem;
}
.seclass {
  font-size: 0.28rem;
  line-height: 0.4rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin: 0.43rem 0.4rem;
  border: 1px solid #979797;
  border-radius: 0.05rem;
  padding: 0.17rem 0.27rem;
  margin-bottom: 0;
}
.seclass input {
  border: none;
}
</style>